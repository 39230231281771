<template lang="pug">
.flex.flex-col.gap-2
  label.btn-confirm.text-sm.font-medium(:for='name' v-if='label') {{ label }}
  Select(
    v-model='input'
  )
    SelectTrigger(
      class='text-sm transition-all focus:ring-0 focus:ring-offset-0 custom-border'
    )
      SelectValue(:placeholder="placeholder")
    SelectContent
      SelectGroup(class='max-h-[96px] overflow-auto scrollbar--hidden')
        SelectItem(:value='item.value' :key='item.value' v-for='item in options') {{ item.label }} 


</template>

<script setup lang="ts">
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Label } from '@/components/ui/label'

const props = defineProps<{
  modelValue: string
  name: string
  label: string
  placeholder: string
  options: {
    label: string
    value: any
  }[]
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const { color } = useBrandColor()

const input = computed({
  get() {
    return props.modelValue
  },
  // setter
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})
</script>

<style scoped lang="stylus">
.custom-border:focus
  border 1px solid v-bind(color)
</style>
